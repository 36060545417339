const global_variables = require('../../config');
const { isNodeASentence } = require('../helpers/node-helpers');

// const { closeDropdown } = require('./dropdown-service.js');
const { cleanString } = require('../helpers/utils');

// this function is shifted in node helper
// function isNodeASentence(node) {
//   if (!global_variables.enable_sentence_merging) {
//     return false;
//   }
//   let result = true;
//   const parentName = node.nodeName.toUpperCase();
//   if (global_variables.sentence_parents.includes(parentName)) {
//     if (node.childNodes.length > 1) {
//       for (const innerNode of node.childNodes) {
//         const childName = innerNode.nodeName.toUpperCase();
//         if (!global_variables.sentence_children.includes(childName)) {
//           result = false;
//         } else if (innerNode.childNodes.length > 1) {
//           result = false;
//         }
//       }
//     } else {
//       result = false;
//     }
//   } else {
//     result = false;
//   }
//   return result;
// }

function checkifDotatokenAndLanguagecodeIsAvailable() {
  if (
    global_variables.url_object.searchParams.has('dota-token') &&
    global_variables.url_object.searchParams.has('language_code')
  ) {
    global_variables.document_url = global_variables.url_object.origin;
    if (!global_variables.document_url.endsWith('/')) {
      global_variables.document_url += '/';
    }
    global_variables.web_owner = true;
    global_variables.dota_token = global_variables.url_object.searchParams.get('dota-token');
    global_variables.selected_lang_code =
      global_variables.url_object.searchParams.get('language_code');
  }
}

function cleanExistingTranslationObj(exitingObj) {
  const newObj = {};
  Object.keys(exitingObj).forEach((key) => {
    newObj[cleanString(key)] = exitingObj[key];
  });
  return newObj;
}

// this is used for set contenteditable to false so that it will be not in edited mode
function remove_contentEditt(node) {
  global_variables.edit_mode_enabled = false;
  // handling the script tag and other unnecesaary text
  const blacklistedTags = ['IMG', 'VIDEO', 'IFRAME', 'NOSCRIPT', 'STYLE', 'SCRIPT'];

  if (node.childNodes && node.childNodes.length > 0) {
    if (!blacklistedTags.includes(node.nodeName.toUpperCase())) {
      for (const innerNode of node.childNodes) {
        remove_contentEditt(innerNode);
      }
    }
  } else {
    if (
      node.parentElement.getAttribute('id') === 'devnagri_optionElementt' ||
      node.parentElement.getAttribute('class') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('devnagri_option') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('id') === 'devnagri_seo_button_id'
    ) {
      return true;
    }
    switch (node.nodeName) {
      case '#text': {
        if (node.textContent.trim().length != 0) {
          if (node.parentElement.nodeName !== 'BODY') {
            node.parentElement.setAttribute('contentEditable', 'false');
          }
        }
        break;
      }
    }
  }
}

// this function is used for calling translate api and here we get specified language text i.e. hindi
// async function GetTranslationsApi() {
//   try {
//     if (global_variables.source_lang_code === global_variables.selected_lang_code) {
//       return {};
//     }

//     if (
//       global_variables.status_api_global_data &&
//       global_variables.status_api_global_data.enable_object_mapping
//     ) {
//       global_variables.enable_object_mapping = true;
//       await getObjectUrlsApi();
//     }

//     const documentUrlWithoutInternalLink = removeInternalLinkFromUrl(
//       global_variables.document_url_full
//     );

//     const currentPageUrlObj = new URL(documentUrlWithoutInternalLink);
//     currentPageUrlObj.searchParams.delete('language_code');
//     currentPageUrlObj.searchParams.delete('mode');
//     currentPageUrlObj.searchParams.delete('dota-token');

//     const translation_api_base_url = `${global_variables.api_baseurl}/translations?language_code=${global_variables.selected_lang_code}&url=${currentPageUrlObj}`;
//     const translation_api_url = translation_api_base_url;
//     // +
//     // "&language_code=" +
//     // lang_code;
//     // const translationData = await callApiRequest(async () => {
//     //   const res = await fetch(translation_api_url, {
//     //     method: 'GET',
//     //     headers: {
//     //       Authorization: `Bearer ${access_token()}`
//     //     }
//     //   });
//     //   if (res.status === 401 || res.status === 403) {
//     //     throw { response: res };
//     //   }
//     //   const response = await res.json();
//     //   return response;
//     // });

//     if (
//       !global_variables.apiCounter &&
//       String(global_variables.prevURL) != String(currentPageUrlObj.href)
//     ) {
//       global_variables.prevURL = global_variables.prevURL
//         ? global_variables.prevURL
//         : currentPageUrlObj.href;
//       global_variables.translationData = await callApiRequest(async () => {
//         const res = await fetch(translation_api_url, {
//           method: 'GET',
//           headers: {
//             Authorization: `Bearer ${access_token()}`
//           }
//         });
//         if (res.status === 401 || res.status === 403) {
//           throw { response: res };
//         }
//         const response = await res.json();
//         return response;
//       });
//       ++global_variables.apiCounter;
//     }

//     if (global_variables.translationData.message === 'success') {
//       // sentence merging changes start
//       if (global_variables.enable_sentence_merging) {
//         const origSentenceDataMap = await FormatTranslations(
//           global_variables.translationData.sentences
//         );

//         const sentenceNodesMapByOrigText = {};
//         Object.entries(global_variables.sentence_nodes).forEach(([key, value]) => {
//           sentenceNodesMapByOrigText[value.original_text] = {
//             parentId: key,
//             translated_text: origSentenceDataMap[value.original_text]
//               ? origSentenceDataMap[value.original_text]
//               : value.original_text,
//             ...value
//           };
//           global_variables.sentence_nodes[key].translated_text = origSentenceDataMap[
//             value.original_text
//           ]
//             ? origSentenceDataMap[value.original_text]
//             : value.original_text;
//         });

//         global_variables.sentence_nodes_by_orig_text = sentenceNodesMapByOrigText;

//         setTranslatedTextToSentenceMapChildren(global_variables.sentence_nodes_by_orig_text);

//         setTranslatedTextToSentenceMapChildren(global_variables.sentence_nodes);

//         setTranslatedContentToSentenceNodes(global_variables.sentence_nodes);
//       }
//       // sentence merging changes end

//       global_variables.translation_api_global_data = await FormatTranslations(
//         global_variables.translationData.sentences
//       );

//       global_variables.translation_api_global_data_by_local_key = formatTranslationDataByLocalKey(
//         global_variables.translation_api_global_data
//       );

//       if (
//         global_variables.status_api_global_data &&
//         !global_variables.status_api_global_data.is_demo
//       ) {
//         await storeValuesInIndexDB(global_variables.translation_api_global_data);
//       }

//       await updateNodesWithTranslatedText(global_variables.translation_api_global_data);
//     }

//     return global_variables.translationData;
//   } catch (error) {
//     console.log(error);
//   }
// }

module.exports = {
  isNodeASentence,
  // initTranslation,
  checkifDotatokenAndLanguagecodeIsAvailable,
  cleanExistingTranslationObj
  // GetTranslationsApi
};
