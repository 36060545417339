const browserStorageHelper = require('../helpers/browser-storage.helpers');
const global_variables = require('../../config');
const { GetTranslationsApi } = require('../services/api-services');
const { cleanString, isObjEmpty } = require('../helpers/utils');
const translationsDb = require('../helpers/translationsDb-helper');
let sentencesCache = [];
let mergeSentenceTranslationMapCache = null;

// call get translation api only once per json
let callGetTranslationApiController = false;

/**
 *
 * @param {string} originalValue
 * @returns if found translated string, else originalValue
 */
function checkTranslationInArrayNodes(originalValue) {
  const matchValue = Object.entries(global_variables.arr_nodes).find(
    ([key, value]) => originalValue === value.originalText && value.translatedText
  );

  return matchValue ? matchValue.translatedText : originalValue;
}

/**
 *
 * @returns translations object if found, else null
 */
async function getTranslationsDataFromTranslationsDb() {
  try {
    const result = await translationsDb.retrieveTranslationsDataFromIndexDb();
    return result ? result : null;
  } catch (error) {
    return null;
  }
}

/**
 *
 * @param {string} original_text
 * @returns translation if found, else original_text
 */
function checkTranslationInTranslationsDbRes(original_text) {
  let matchSentence = sentencesCache.find((obj) => obj.sentence === original_text);

  if (!matchSentence) {
    matchSentence = checkTranslationInSentenceNodes(original_text, true);
    return matchSentence ? matchSentence : original_text;
  }

  return matchSentence.translation;
}

// split sentece_nodes
function splitSentences(str, separator) {
  const tempStr = str.split(separator);
  return tempStr;
}

/**
 *
 * @param {string} separator
 * @returns splited sentence_nodes object
 */
function splitSentenceNodes(separator, isCallFromTranslationsDb) {
  try {
    const translationMap = {};

    if (isCallFromTranslationsDb) {
      if (mergeSentenceTranslationMapCache) {
        return mergeSentenceTranslationMapCache;
      }

      sentencesCache.forEach((entry) => {
        const originalText = entry.sentence;
        const translatedText = entry.translation;

        if (
          originalText &&
          originalText.includes(separator) &&
          translatedText &&
          translatedText.includes(separator)
        ) {
          const originalParts = splitSentences(originalText, separator);
          const translatedParts = splitSentences(translatedText, separator);
          originalParts.forEach((part, index) => {
            if (translatedParts[index]) {
              let temp = part.trim();
              translationMap[temp] = translatedParts[index].trim();
            }
          });
        }
      });

      mergeSentenceTranslationMapCache = translationMap;
    } else {
      for (let key in global_variables.sentence_nodes) {
        const entry = global_variables.sentence_nodes[key];

        if (entry.original_text && entry.translated_text) {
          const originalParts = splitSentences(entry.original_text, separator);
          const translatedParts = splitSentences(entry.translated_text, separator);

          originalParts.forEach((part, index) => {
            if (translatedParts[index]) {
              let temp = part.trim();
              translationMap[temp] = translatedParts[index].trim();
            }
          });
        }
      }
    }

    return translationMap;
  } catch (error) {
    return {};
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated string, else originalValue
 */
function checkTranslationInSentenceNodes(originalValue, isCallFromTranslationsDb = false) {
  const sentenceObj = splitSentenceNodes(
    global_variables.sentence_merge_separator_receive,
    isCallFromTranslationsDb
  );

  if (sentenceObj.hasOwnProperty(originalValue)) {
    return sentenceObj[originalValue];
  }
  return originalValue;
}

/**
 *
 * @param {string} originalValue
 * @returns if found - translated value, else originalValue
 */
async function checkTranslationInIndexDb(originalValue) {
  try {
    // check if indexDb is supported by browser
    if (window.indexedDB) {
      const translation = await browserStorageHelper.getParticularValueFromIndexDB(originalValue);
      if (translation) {
        return translation;
      } else {
        return originalValue;
      }
    } else {
      return originalValue;
    }
  } catch (error) {
    return originalValue;
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated value, else originalValue
 */
async function checkTranslationInGetTranslationApiRes(
  originalValue,
  callGetTranslationApi = false
) {
  try {
    if (callGetTranslationApi && callGetTranslationApiController) {
      // Note: need to pass 'true' as a parameter if newly implemented indexDb is used/merged.
      callGetTranslationApiController = false;
      await GetTranslationsApi(true);
    }
    const matchSentence =
      global_variables.translationData.sentences &&
      global_variables.translationData.sentences.find((obj) => obj.sentence === originalValue);
    if (matchSentence) {
      return matchSentence.translation;
    } else {
      return originalValue;
    }
  } catch (error) {
    return originalValue;
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated value, else originalValue
 * description: checks segment translation in indexDb, 'global_variables.translationData.sentences', 'global_variables.sentence_nodes'
 */
async function translator(originalValue, isDataFoundInIndexDb) {
  try {
    let translatedValue = '';

    if (originalValue === '' || !isNaN(Number(originalValue))) {
      return originalValue;
    } else {
      let original_text = cleanString(originalValue);

      // step: 1 - check in global_variables.arr_nodes
      // translatedValue = await checkTranslationInArrayNodes(original_text);

      // check if translated string is NOT same, assuming translation is found in 'global_variables.arr_nodes'
      // if (original_text !== translatedValue) {
      //   return translatedValue;
      // }

      // check if translated string is same, assuming no translation found in step: 1
      // if (original_text === translatedValue)
      // step: 2 - check in indexDb
      if (isDataFoundInIndexDb) {
        translatedValue = await checkTranslationInTranslationsDbRes(original_text);
        return translatedValue;
      } else {
        // check if translated string is NOT same, assuming translation is found in indexDb
        // if (original_text !== translatedValue) {
        //   return translatedValue;
        // }

        // check if translated string is same, assuming no translation found in step: 2
        // if (original_text === translatedValue)
        {
          // step: 3 - check in global_variables.translationData.sentences
          translatedValue = await checkTranslationInGetTranslationApiRes(original_text);
        }

        // check if translated string is NOT same, assuming translation is found in 'global_variables.translationData.sentences'
        if (original_text !== translatedValue) {
          return translatedValue;
        }

        // check if translated string is same, assuming no translation found in step: 3
        if (original_text === translatedValue) {
          // step: 4 - check in 'global_variables.sentence_nodes'
          translatedValue = await checkTranslationInSentenceNodes(original_text);
        }

        // check if translated string is NOT same, assuming translation is found in 'global_variables.sentence_nodes'
        if (original_text !== translatedValue) {
          return translatedValue;
        }

        // check if translated string is same, assuming no translation found in step: 4
        if (original_text === translatedValue) {
          /**
           * step: 5 - call get translations api and check in 'global_variables.translationData.sentences'
           * call get translation api only if it is not called previously or we don't have translation data in any form i.e. translationsDb or global_variables.translationData.sentences
           */
          if (isObjEmpty(global_variables.translationData)) {
            translatedValue = await checkTranslationInGetTranslationApiRes(original_text, true);
            callGetTranslationApiController = false;

            // if not found check again in merged sentences - 'global_variables.sentence_nodes'
            if (original_text === translatedValue) {
              // After calling getTranslation api => check againg in -> step: 4 - check in 'global_variables.sentence_nodes'
              translatedValue = await checkTranslationInSentenceNodes(original_text);
            }
          }

          if (translatedValue) {
            return translatedValue;
          } else {
            return originalValue;
          }
        }
        return originalValue;
      }
    }
  } catch (error) {
    return originalValue;
  }
}

// check if 'decription' key contains HTML tags
function isHtmlString(str) {
  if (/<[^>]+>/g.test(str)) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    // check if it's valid HTML or plain text
    return doc.body.innerHTML !== str;
  }
  return false;
}

// unescape the string from escaped characters like \n and \"
function unescapeHtml(escapedHtml) {
  const unescapedHtml = escapedHtml.replace(/\\n/g, '\n').replace(/\\"/g, '"');

  return unescapedHtml;
}

// extract text from HTML, translate, and replace it
async function translateDescriptionText(descriptionHtml, isDataFoundInIndexDb) {
  try {
    // unescape the HTML string to convert escape sequences back to normal characters
    const unescapedHtml = unescapeHtml(descriptionHtml);

    // create a new DOMParser to parse the unescaped HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(unescapedHtml, 'text/html');

    // create an array to store the text nodes that need to be translated
    const textNodes = [];

    // traverse all text nodes in the DOM
    function getTextNodes(node) {
      if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
        textNodes.push(node);
      }
      for (let child of node.childNodes) {
        getTextNodes(child);
      }
    }

    // start the traversing from the root of the document which is the <section>
    getTextNodes(doc.body);

    // extract and translate all text nodes
    const translations = await Promise.all(
      textNodes.map(async (node) => {
        let originalText = node.textContent.trim();

        if (originalText) {
          const translatedText = await translator(originalText, isDataFoundInIndexDb);
          return { node, translatedText };
        }
      })
    );

    // replace the text content with the translated text
    translations.forEach(({ node, translatedText }) => {
      if (translatedText !== undefined) {
        node.textContent = translatedText;
      }
    });

    // return the modified HTML as a string
    return doc.body.innerHTML;
  } catch (error) {
    return descriptionHtml;
  }
}

module.exports = {
  // main function to parse the JSON and translate values
  async translateJson(inputJson) {
    try {
      if (inputJson === null || inputJson === undefined) {
        console.error('Invalid input JSON');
        return inputJson;
      }

      let isDataFoundInIndexDb = false;
      const translations = await getTranslationsDataFromTranslationsDb();
      if (
        translations &&
        Array.isArray(translations.sentences) &&
        translations.sentences.length > 0
      ) {
        sentencesCache = translations.sentences;
        isDataFoundInIndexDb = true;
        mergeSentenceTranslationMapCache = null;
      }

      // call get translation API only once per json
      callGetTranslationApiController = true;

      // const translatedJson = { ...inputJson };

      // stack to keep track of objects/arrays
      const stack = [{ obj: inputJson, parentKey: null }];

      while (stack.length > 0) {
        const { obj, parentKey } = stack.pop();

        // check if the current object is an array or an object
        if (Array.isArray(obj)) {
          for (let i = 0; i < obj.length; i++) {
            if (typeof obj[i] === 'object' && obj[i] !== null) {
              stack.push({ obj: obj[i], parentKey: i });
            } else if (typeof obj[i] === 'string') {
              // If it's a string, translate it
              obj[i] = await translator(obj[i], isDataFoundInIndexDb);
            }
          }
        } else if (typeof obj === 'object' && obj !== null) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const objKeyValue = obj[key];
              if (typeof objKeyValue === 'object' && objKeyValue !== null) {
                stack.push({ obj: objKeyValue, parentKey: key });
              } else if (typeof objKeyValue === 'string') {
                if (key === 'description' && isHtmlString(objKeyValue)) {
                  // traverse nodes and translate text
                  let result = await translateDescriptionText(objKeyValue);
                  obj[key] = result;
                } else {
                  // if it's a string, translate it
                  obj[key] = await translator(objKeyValue, isDataFoundInIndexDb);
                }
              }
            }
          }
        }
      }

      // translate direct keys
      // for (const key in inputJson) {
      //   const jsonValue = inputJson[key];
      //   // check if JSON value is string
      //   if (typeof jsonValue === 'string') {
      //     translatedJson[key] = await translator(jsonValue, isDataFoundInIndexDb);
      //     const d = await translator(jsonValue, isDataFoundInIndexDb);
      //   }
      // }

      return inputJson;
    } catch (error) {
      console.log('Error: no translation found.', error);
      return inputJson;
    }
  }
};
