const global_variables = require('../../config');

// toggling(display) language button
function openDropdown() {
  if (
    global_variables.should_render_ui &&
    global_variables.should_render_ui.toLowerCase() === 'custom'
  ) {
    return true;
  }
  const x = document.getElementById('devnagri_dropdown');
  if (x && x.style.display === 'block') {
    x.style.display = 'none';
  } else {
    let width;

    const dotaSelectContainer = document.querySelector('.devnagri_open_button');
    if (dotaSelectContainer) {
      width = dotaSelectContainer.offsetWidth;
    }

    const dropdownContainer = document.getElementById('devnagri_dropdown');

    if (dropdownContainer) {
      dropdownContainer.setAttribute('style', `width:${width}px;`);
    }
    x.style.display = 'block';
  }
}
// for closing dropdown
function closeDropdown() {
  if (
    global_variables.should_render_ui &&
    global_variables.should_render_ui.toLowerCase() === 'custom'
  ) {
    return true;
  }
  const id = document.getElementById('devnagri_dropdown');
  if (id.style.display === 'block') {
    id.style.display = 'none';
  }
}

function openProxyDropdown() {
  const x = document.getElementById('Devnagri_dropdown');
  if (x.style.display === 'block') {
    x.style.display = 'none';
  } else {
    x.style.display = 'block';
  }
}

function setEventHandlerOfProxyDropDown() {
  const dotaSelectContainer = document.querySelector('#Devnagri_open_button');
  let width;
  if (dotaSelectContainer && dotaSelectContainer.offsetWidth) {
    width = dotaSelectContainer.offsetWidth;
  }
  const dropdownContainer = document.getElementById('Devnagri_dropdown');
  if (dropdownContainer) {
    dropdownContainer.setAttribute('style', `width:${width}px;`);
  }

  const languageWrapper = document.getElementById('Devnagri_written_lang_wrapper');
  if (languageWrapper) {
    languageWrapper.removeEventListener('click', openProxyDropdown);
    languageWrapper.addEventListener('click', openProxyDropdown);
  }
  const arrowWrapper = document.getElementById('Devnagri_arrow_up_wrapper');
  if (arrowWrapper) {
    arrowWrapper.removeEventListener('click', openProxyDropdown);
    arrowWrapper.addEventListener('click', openProxyDropdown);
  }
}

function removeExistingProxyDropdown() {
  const dropdownElements = document.getElementsByClassName('devnagri_outermostdivclass');

  if (dropdownElements && dropdownElements[0]) {
    dropdownElements[0].remove();
  }
}

function setDropdownPosition(ui_position) {
  if (ui_position) {
    if (ui_position.right != '') {
      global_variables.ui_right = ui_position.right;
    } else if (!ui_position.left) {
      global_variables.ui_right = '3%';
    }

    if (ui_position.bottom != '') {
      global_variables.ui_bottom = ui_position.bottom;
    } else {
      global_variables.ui_bottom = '4%';
    }

    if (ui_position.left != '') {
      global_variables.ui_left = ui_position.left;
    }
  } else {
    global_variables.ui_right = '3%';
    global_variables.ui_bottom = '4%';
  }
}

module.exports = {
  openDropdown,
  closeDropdown,
  openProxyDropdown,
  setEventHandlerOfProxyDropDown,
  removeExistingProxyDropdown,
  setDropdownPosition
};
